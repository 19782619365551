export const TableJSON = [
  {
    id: 1,
    metric_type: "CPU",
    metric_status: "success",
    metric_health: "Good",
    findings: [
      { id: 1, type: "info", msg: "The average CPU % was 67%." },
      { id: 2, type: "warning", msg: "The peak CPU % was 100% (4/29/21)." },
      {
        id: 3,
        type: "danger",
        msg: "For the historical period analyzed, the average CPU utililization increased 21%.",
      },
    ],
  },
  {
    id: 2,
    metric_type: "CPU",
    metric_status: "warning",
    metric_health: "warning",
    findings: [
      { id: 1, type: "info", msg: "The average CPU % was 67%." },
      { id: 2, type: "warning", msg: "The peak CPU % was 100% (4/29/21)." },
      {
        id: 3,
        type: "danger",
        msg: "For the historical period analyzed, the average CPU utililization increased 21%.",
      },
    ],
  },

  {
    id: 1,
    metric_type: "CPU",
    metric_status: "warning",
    metric_health: "warning",
    findings: [
      { id: 1, type: "info", msg: "The average CPU % was 67%." },
      { id: 2, type: "warning", msg: "The peak CPU % was 100% (4/29/21)." },
      {
        id: 3,
        type: "danger",
        msg: "For the historical period analyzed, the average CPU utililization increased 21%.",
      },
    ],
  },
  {
    id: 1,
    metric_type: "CPU",
    metric_status: "success",
    metric_health: "Good",
    findings: [
      { id: 1, type: "info", msg: "The average CPU % was 67%." },
      { id: 2, type: "warning", msg: "The peak CPU % was 100% (4/29/21)." },
      {
        id: 3,
        type: "danger",
        msg: "For the historical period analyzed, the average CPU utililization increased 21%.",
      },
    ],
  },
];

export const ReportDetailsTableJSON = [
  {
    id: 1,
    metric_type: "cpu_utilization",
    metric_status: "Critical",
    metric_health: "critical",
    findings: [
      {
        id: 3,
        type: "danger",
        msg: "The average CPU utililization is 91%.",
      },
    ],
  },
  {
    id: 2,
    metric_type: "no_of_cores",
    metric_status: "Info",
    metric_health: "Info",
    findings: [
      {
        id: 3,
        type: "danger",
        msg: "The average Number of Cores is 0.19.",
      },
    ],
  },
  {
    id: 3,
    metric_type: "CPU-ms",
    metric_status: "Info",
    metric_health: "Info",
    findings: [
      {
        id: 3,
        type: "success",
        msg: "The average CPU ms is 118,266.34 ms",
      },
    ],
  },
  {
    id: 4,
    metric_type: "disk_space_utilization",
    metric_status: "Good",
    metric_health: "Good",
    findings: [
      {
        id: 3,
        type: "info",
        msg: "The average Disk Space utilization is 10%.",
      },
    ],
  },
  {
    id: 5,
    metric_type: "disk_arm_utilization",
    metric_status: "Good",
    metric_health: "Good",
    findings: [
      {
        id: 3,
        type: "info",
        msg: "The average Disk Space utilization is 14%.",
      },
    ],
  },
  {
    id: 6,
    metric_type: "disk_response_time",
    metric_status: "Warning",
    metric_health: "Warning",
    findings: [
      {
        id: 3,
        type: "info",
        msg: "The average Disk Response Time is 34 ms.",
      },
    ],
  },
  {
    id: 7,
    metric_type: "total_disk_ops",
    metric_status: "Info",
    metric_health: "Info",
    findings: [
      {
        id: 3,
        type: "info",
        msg: "The average Total Disk Operations is 720 ops/sec.",
      },
    ],
  },
  {
    id: 8,
    metric_type: "faulting_rate",
    metric_status: "Info",
    metric_health: "Info",
    findings: [
      {
        id: 3,
        type: "info",
        msg: "The average Faulting Rate is 300 faults/sec.",
      },
    ],
  },
  {
    id: 9,
    metric_type: "ethernet_line_utilization",
    metric_status: "Warning",
    metric_health: "Warning",
    findings: [
      {
        id: 3,
        type: "info",
        msg: "The average Ethernet Line Utilization is 54%.",
      },
    ],
  },
  {
    id: 10,
    metric_type: "response_time_5250",
    metric_status: "Critical",
    metric_health: "Critical",
    findings: [
      {
        id: 3,
        type: "info",
        msg: "The average 5250 Response Time is 8.4 Seconds.",
      },
    ],
  },
  {
    id: 11,
    metric_type: "total_transactions",
    metric_status: "Info",
    metric_health: "Info",
    findings: [
      {
        id: 3,
        type: "info",
        msg: "The average Total Transaction is 810.",
      },
    ],
  },
];

export const CPUMemoryData = [
  {
    id: 1,
    memory_pool: "Pool 1 (*MACHINE)	",
    memory: 5,
    memory_pool_percentage: 3.5,
    fault_rate: 7.2,
    status: "Critical",
    health: "critical",
  },
  {
    id: 1,
    memory_pool: "Pool 1 (*MACHINE)	",
    memory: 5,
    memory_pool_percentage: 3.5,
    fault_rate: 7.2,
    status: "Critical",
    health: "critical",
  },
  {
    id: 1,
    memory_pool: "Pool 1 (*MACHINE)	",
    memory: 5,
    memory_pool_percentage: 3.5,
    fault_rate: 7.2,
    status: "Critical",
    health: "critical",
  },
  {
    id: 1,
    memory_pool: "Pool 1 (*MACHINE)	",
    memory: 5,
    memory_pool_percentage: 3.5,
    fault_rate: 7.2,
    status: "Critical",
    health: "critical",
  },
  {
    id: 1,
    memory_pool: "Pool 1 (*MACHINE)	",
    memory: 5,
    memory_pool_percentage: 3.5,
    fault_rate: 7.2,
    status: "Critical",
    health: "critical",
  },
  {
    id: 1,
    memory_pool: "Pool 1 (*MACHINE)	",
    memory: 5,
    memory_pool_percentage: 3.5,
    fault_rate: 7.2,
    status: "Critical",
    health: "critical",
  },
  {
    id: 1,
    memory_pool: "Pool 1 (*MACHINE)	",
    memory: 5,
    memory_pool_percentage: 3.5,
    fault_rate: 7.2,
    status: "Critical",
    health: "critical",
  },
  {
    id: 1,
    memory_pool: "Pool 1 (*MACHINE)	",
    memory: 5,
    memory_pool_percentage: 3.5,
    fault_rate: 7.2,
    status: "Critical",
    health: "critical",
  },
  {
    id: 1,
    memory_pool: "Pool 1 (*MACHINE)	",
    memory: 5,
    memory_pool_percentage: 3.5,
    fault_rate: 7.2,
    status: "Critical",
    health: "critical",
  },
];

export const TopJobCPU = [
  {
    id: 1,
    job_name: "WareHouse",
    user_name: "ABC",
    current_user: "ABCDEF",
    job_number: 1100122,
    cpu_ms: 510023,
  },
  {
    id: 1,
    job_name: "WareHouse",
    user_name: "ABC",
    current_user: "ABCDEF",
    job_number: 1100122,
    cpu_ms: 510023,
  },
  {
    id: 1,
    job_name: "WareHouse",
    user_name: "ABC",
    current_user: "ABCDEF",
    job_number: 1100122,
    cpu_ms: 510023,
  },
  {
    id: 1,
    job_name: "WareHouse",
    user_name: "ABC",
    current_user: "ABCDEF",
    job_number: 1100122,
    cpu_ms: 510023,
  },
  {
    id: 1,
    job_name: "WareHouse",
    user_name: "ABC",
    current_user: "ABCDEF",
    job_number: 1100122,
    cpu_ms: 510023,
  },
];

// let arr = [
//   {
//     id: 1,
//     type: "critical",
//     msg: "Critical",
//     health: "90%",
//     serverLocation: "Chicago, IL",
//     serverName: "11-2315",
//     country: "India",
//     region: "Asia",
//     state: "Bangalore, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//   },
//   {
//     id: 2,
//     serverName: "11-2315",
//     type: "good",
//     msg: "Good",
//     health: "90%",
//     serverLocation: "Chicago, IL",
//     country: "India",
//     region: "Asia",
//     state: "Bangalore, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//   },
//   {
//     id: 3,
//     serverName: "11-9432",
//     type: "good",
//     msg: "Good",
//     health: "50%",
//     serverLocation: "Chicago, IL",
//     country: "Singapore",
//     region: "Asia",
//     state: "Bangalore, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//   },
//   {
//     id: 4,
//     serverName: "11-2315",
//     type: "critical",
//     state: "Lower Saxony, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "Critical",
//     health: "50%",
//     serverLocation: "Chicago, IL",
//   },
//   {
//     id: 5,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "Warning",
//     health: "90%",
//     serverLocation: "St. Louis, MO",
//   },
//   {
//     id: 6,
//     serverName: "10-5713",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "good",
//     health: "90%",
//     serverLocation: "St. Louis, MO",
//   },
//   {
//     id: 5,
//     serverName: "11-9432",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "good",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 8,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 9,
//     serverName: "11-2315",
//     type: "critical",
//     state: "Lower Saxony, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 10,
//     serverName: "10-5713",
//     type: "critical",
//     state: "Lower Saxony, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 11,
//     serverName: "11-9432",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "warning",
//     health: "90%",
//   },
//   {
//     id: 12,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 13,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 14,
//     serverName: "11-9432",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "good",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 15,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 16,
//     serverName: "11-2315",
//     type: "critical",
//     state: "Hesse, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 17,
//     serverName: "10-5713",
//     type: "critical",
//     state: "Hesse, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 18,
//     serverName: "11-9432",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "warning",
//     health: "90%",
//   },
//   {
//     id: 19,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 20,
//     serverName: "11-9432",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "good",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 21,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 22,
//     serverName: "11-2315",
//     type: "critical",
//     state: "Hesse, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 23,
//     serverName: "10-5713",
//     type: "critical",
//     state: "Luxor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 24,
//     serverName: "11-9432",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "warning",
//     health: "90%",
//   },
//   {
//     id: 25,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 26,
//     serverName: "11-9432",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "good",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 27,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 28,
//     serverName: "11-2315",
//     type: "critical",
//     state: "Luxor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 29,
//     serverName: "10-5713",
//     type: "critical",
//     state: "Luxor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 30,
//     serverName: "11-9432",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "warning",
//     health: "90%",
//   },
//   {
//     id: 31,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 1,
//     serverName: "11-2315",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "Critical",
//     health: "90%",
//     serverLocation: "Chicago, IL",
//   },
//   {
//     id: 2,
//     serverName: "10-5713",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "Critical",
//     health: "90%",
//     serverLocation: "Chicago, IL",
//   },
//   {
//     id: 3,
//     serverName: "11-9432",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "Good",
//     health: "50%",
//     serverLocation: "Chicago, IL",
//   },
//   {
//     id: 4,
//     serverName: "11-2315",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "Critical",
//     health: "50%",
//     serverLocation: "Chicago, IL",
//   },
//   {
//     id: 5,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "Warning",
//     health: "90%",
//     serverLocation: "St. Louis, MO",
//   },
//   {
//     id: 6,
//     serverName: "10-5713",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "good",
//     health: "90%",
//     serverLocation: "St. Louis, MO",
//   },
//   {
//     id: 5,
//     serverName: "11-9432",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "good",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 8,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 9,
//     serverName: "11-2315",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 10,
//     serverName: "10-5713",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 11,
//     serverName: "11-9432",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "warning",
//     health: "90%",
//   },
//   {
//     id: 12,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 13,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 14,
//     serverName: "11-9432",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "good",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 15,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 16,
//     serverName: "11-2315",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 17,
//     serverName: "10-5713",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 18,
//     serverName: "11-9432",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "warning",
//     health: "90%",
//   },
//   {
//     id: 19,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 20,
//     serverName: "11-9432",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "good",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 21,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 22,
//     serverName: "11-2315",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 23,
//     serverName: "10-5713",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 24,
//     serverName: "11-9432",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "warning",
//     health: "90%",
//   },
//   {
//     id: 25,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 26,
//     serverName: "11-9432",
//     type: "good",
//     state: "Kotoor, Africa",
//     country: "Egypt",
//     region: "Africa",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "good",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 27,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
//   {
//     id: 28,
//     serverName: "11-2315",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 29,
//     serverName: "10-5713",
//     type: "critical",
//     country: "India",
//     region: "Asia",
//     state: "Mumbai, India",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "Critical",
//     health: "90%",
//   },
//   {
//     id: 30,
//     serverName: "11-9432",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     serverLocation: "St. Louis, MO",
//     msg: "warning",
//     health: "90%",
//   },
//   {
//     id: 31,
//     serverName: "11-2315",
//     type: "warning",
//     state: "Bavaria, Germany",
//     country: "Germany",
//     region: "Europe",
//     department: "IT",
//     orderEntry: "Order-Entry",
//     msg: "warning",
//     serverLocation: "St. Louis, MO",
//     health: "90%",
//   },
// ];
// let groupOptionsList = [
//   {
//     id: 1,
//     value: "type",
//     viewText: "Health Type",
//   },
//   {
//     id: 2,
//     value: "serverLocation",
//     viewText: "Server Locations",
//   },
//   {
//     id: 3,
//     value: "serverName",
//     viewText: "Servers",
//   },
//   {
//     id: 4,
//     value: "country",
//     viewText: "Country",
//   },
//   {
//     id: 4,
//     value: "region",
//     viewText: "Region",
//   },
//   {
//     id: 4,
//     value: "state",
//     viewText: "State",
//   },
//   {
//     id: 4,
//     value: "department",
//     viewText: "Department",
//   },
//   {
//     id: 4,
//     value: "orderEntry",
//     viewText: "Order Entry",
//   },
// ];

export const SPECIFIC_REPORT = [
  { id: 1, metric_name: "Model", value: "500" },
  { id: 2, metric_name: "Feature Code", value: "8952 - 7459" },
  { id: 3, metric_name: "P Group", value: "P05" },
  { id: 4, metric_name: "Serial Number", value: "10 - 123456" },
  { id: 5, metric_name: "Operating System", value: "V7R3" },
  { id: 6, metric_name: "Total Frame Course", value: "2" },
  { id: 7, metric_name: "Total LPAR Course", value: "1" },
  { id: 8, metric_name: "Virtuals Mode", value: "1 - capped" },
  { id: 9, metric_name: "Total Memory (MB)", value: "4 GB" },
  { id: 10, metric_name: "LPAR Memory (MB)", value: "2 GB" },
  { id: 11, metric_name: "Total Disk Installed (GB)", value: "1,507" },
  { id: 12, metric_name: "Total Disk Used", value: "1,125 - 76%" },
];

export const SHORT_METRICS = {
  "cpw": 'CPW', 
  "cpu_ms": 'CPUMS', 
  "no_of_cores": 'CORES', 
  "faulting_rate": 'TOPF', 
  "cache_hit_perc": 'CHPRC', 
  "total_disk_ops": 'DSKO', 
  "cpu_utilization": 'CPU', 
  "read_write_ratio": 'RWRAT', 
  "executing_summary": 'EXSUM', 
  "disk_response_time": 'DSKR', 
  "pool_faulting_rate": 'PFRT', 
  "response_time_5250": 'RSPTM', 
  "total_transactions": 'TTRN', 
  "disk_arm_utilization": 'DSKU', 
  "memory_size_faulting": 'SZFLT', 
  "top_jobs_utilisation": 'TJUT', 
  "machine_pool_faulting": 'MFLT', 
  "system_specifications": 'SYSP', 
  "disk_space_utilization": 'DSKA', 
  "top_pool_faulting_rate": 'TOPF', 
  "ethernet_line_utilization": 'ETH'
}
