import React from "react";
import { Outlet } from "react-router-dom";


function Settings() {
  return (
    <Outlet />
  );
}

export default Settings;
