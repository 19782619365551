import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";

import { createSearchParams, useSearchParams } from "react-router-dom";
import React, { Suspense, useCallback, useEffect, useState } from "react";

import {pieChartToggleState, topJobsToggleState} from "../../store/slices/charts/alertChartsSlice";
import {getParametersFromLocalStorage, handlePrintDetails, saveParametersIntoLocalStorage} from "../../helpers/commonHelper";

// Lazy-loaded components
const ReportGraphDetails = React.lazy(() => import("./ReportGraphDetails"));
const SectionHeader = React.lazy(() => import("../components/SectionHeader"));
const AlertView = React.lazy(() => import("./EnterpriseServerView/AlertView"));
const ToggleSystems = React.lazy(() => import("./EnterpriseServerView/ToggleSystems"));
const Metrics = React.lazy(() => import("./EnterpriseServerView/MetricsPage/Metrics"));
const TopJobs = React.lazy(() => import("../components/AlertMetricGraph/TopJobs/TopJobs"));
const TopJobsPieChartToggle = React.lazy(() => import("./EnterpriseServerView/MetricsPage/TopJobsPieChartToggle")); 


function MetricsPage() {
  const topJobsToggle = useSelector(topJobsToggleState);
  const pieChartToggle = useSelector(pieChartToggleState);
  const sysData = getParametersFromLocalStorage("systemData");

  const [selectedSystem, setSelectedSystem] = useState(sysData);
  const [filteredBreadCrumbsList, setFilteredBreadCrumbsList] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const serverId = selectedSystem?.id
  const host = selectedSystem?.entity_name
  const serialNo = selectedSystem?.serial_number
  const lparName = selectedSystem?.serial_number || "";
  const systemType = selectedSystem?.entity_name

  const params = { sysId: serverId, serialNo, systemType, host };

  const breadCrumbsList = [
    { id: 1, name: "Enterprise", url: "/enterprise-server" },
    {
      id: 2,
      name: host,
      ...(systemType === "Frame" && {
        url: `/server?${createSearchParams(params)}`,
      }),
    },
  ];

  const breadCrumbsList2 = [
    { id: 3, name: serialNo, url: "" },
    { id: 4, name: lparName, url: "" },
  ];

  const groupOptionsList = [
    { id: 3, value: "serverName", viewText: "Server Serial No." },
    { id: 1, value: "type", viewText: "Server Health Type" },
  ];

  const handleSystemChange = useCallback((systemData) => {
    setSelectedSystem(systemData);
    // setSearchParams({
    //   sysId: systemData?.id,
    //   serialNo: systemData?.serial_number,
    //   systemType: systemData?.entity_name,
    //   host: systemData?.entity_name,
    //   // ...(systemType === "host" && { entityName: entity_name }),
    //   ...(systemData?.entity_name == "5" && {
    //     entityName: systemData?.entity_name,
    //   }),
    // });
    saveParametersIntoLocalStorage("systemData", systemData);
  }, []);

  useEffect(() => {
    const updatedBreadCrumbs =
      systemType === "Frame"
        ? [...breadCrumbsList, ...breadCrumbsList2]
        : [...breadCrumbsList, breadCrumbsList2[0]];
    setFilteredBreadCrumbsList(updatedBreadCrumbs);
  }, [host, serverId, systemType]);

  const fallbackLoader = <BeatLoader color="#366bd6" />;

  const renderSystemOption = (option) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p
        className={`metricsCard ${option.type.toLowerCase()}`}
        style={{ margin: "0 0.5rem", width: "5%", padding: "0.5rem 0.7rem" }}
      />
      <p className="value-options">{`${option.entity_name} - ${option.entity_description} - ${option.serial_number}`}</p>
    </div>
  );

  return (
    <div className="enterprise_wrapper">
      <Suspense fallback={fallbackLoader}>
        <SectionHeader
          title="Metrics"
          btnClickHandler={handlePrintDetails}
          breadCrumbsList={filteredBreadCrumbsList}
        />
      </Suspense>

      <Suspense fallback="">
        <ToggleSystems
          selectedSystem={selectedSystem}
          activeTab={() => {}}
          handleSystemChange={handleSystemChange}
          systemOptionTemplate={renderSystemOption}
          groupOptionsList={groupOptionsList}
          selectedSystemTemplate={(option, props) =>
            option ? (
              <div className="value-options">{`${option.entity_name} - ${option.entity_description} - ${option.serial_number}`}</div>
            ) : (
              <span>{props.placeholder}</span>
            )
          }
        />
      </Suspense>

      <div className="servers_wrapper-alerts">
        <div
          className="server_wrapper-alert"
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "25rem",
          }}
        >
          <Suspense fallback={fallbackLoader}>
            <Metrics selectedSystem={selectedSystem}/>
          </Suspense>
          <Suspense fallback={fallbackLoader}>
            <TopJobsPieChartToggle />
          </Suspense>
        </div>
        <Suspense fallback={fallbackLoader}>
          <AlertView width="36%" />
        </Suspense>
      </div>

      <Suspense fallback={fallbackLoader}>
        <ReportGraphDetails selectedSystem={selectedSystem}/>
      </Suspense>

      {(topJobsToggle || pieChartToggle) && (
        <Suspense fallback={fallbackLoader}>
          <TopJobs alertPage={true} selectedSystem={selectedSystem}/>
        </Suspense>
      )}
    </div>
  );
}

export default MetricsPage;
